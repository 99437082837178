import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import {
  fetchGeneralReportTemplate,
  fetchGeneralReport,
  submitReport,
  fetchGeneralReportTemplateID,
} from "../../api/reportApi";
import { updateMidseasonOrReport } from "../../api/classApi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./MidSeasonReport.css";

const MidSeasonReportForm = ({ studentId, classId, onClose }) => {
  const [sections, setSections] = useState([]);
  const [templateID, setTemplateID] = useState(null);
  const { userID, name } = useContext(UserContext);
  //const [status, setStatus] = useState(0); // Default to "Draft"
  const [comments, setComments] = useState("");

  useEffect(() => {
    const getTemplate = async () => {
      const template = await fetchGeneralReportTemplate("Mid-Season Report");
      console.log(template);
      setTemplateID(template._id);
      setSections(
        template.sections.map((section) => ({
          section_name: section.section_name,
          options: section.options,
          selected_option: "",
        }))
      );
    };

    const getTemplateById = async (_id, existingSections) => {
      const template = await fetchGeneralReportTemplateID(_id);
      setTemplateID(template._id);
      setSections(
        template.sections.map((section) => {
          // Find the corresponding section in the existing report
          const existingSection = existingSections.find(
            (s) => s.section_name === section.section_name
          );
          return {
            section_name: section.section_name,
            options: section.options,
            selected_option: existingSection ? existingSection.option : "", // Set the selected option if it exists
          };
        })
      );
    };

    const getReport = async () => {
      try {
        const report = await fetchGeneralReport(classId, userID, studentId);
        if (report.report == null) {
          getTemplate(); // If no report exists, fetch the template
        } else {
          // If a report exists, use it to populate the form
          getTemplateById(report.template, report.sections);
          setComments(report.comments || ""); // Populate the comments if available
        }
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    };

    getReport(); // Ensure this runs when the component mounts
  }, [userID, classId, studentId]);

  // Handle selection of options for each section
  const handleSectionChange = (index, value) => {
    const updatedSections = [...sections];
    updatedSections[index].selected_option = value;
    setSections(updatedSections);
  };

  // Handle form submission
  const handleSubmit = async () => {
    const preparedSections = sections.map((section) => ({
      section_name: section.section_name,
      option: section.selected_option,
    }));
    try {
      await submitReport({
        templateID,
        studentId,
        classId,
        instructorId: userID,
        sections: preparedSections,
        comments,
        status: 1, // Mark as submitted
      });

      await updateMidseasonOrReport(classId, studentId, "midseason");

      toast.success("Report submitted successfully!");
      onClose();
    } catch (error) {
      toast.error("Error submitting report: " + error.message);
      console.error("Error submitting report: ", error);
    }
  };

  return (
    <div className="mid-season-report-container">
      <h2>Mid-Season Report</h2>
      <div>
        <div>{name}</div>
      </div>
      {sections.map((section, index) => (
        <div className="form-group" key={index}>
          <label>{section.section_name}</label>
          <select
            className="dropdown"
            value={section.selected_option}
            onChange={(e) => handleSectionChange(index, e.target.value)}
          >
            <option value="">Select an option</option>
            {section.options.map((option, idx) => (
              <option key={idx} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      ))}
      <div className="mid-season-comment form-group">
        <label>Instructor Comments</label>
        <textarea
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </div>
      <button className="submit-button" onClick={handleSubmit}>
        Submit Report
      </button>
    </div>
  );
};

export default MidSeasonReportForm;
