import React, { useState, useEffect } from "react";
import { fetchCourses } from "../../api/courseApi";
import { fetchReportLevels } from "../../api/reportApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CreateReportCardTemplate.css";

const CreateReportCardTemplate = () => {
  const [courses, setCourses] = useState([]);
  const [reportLevels, setReportLevels] = useState([]);
  const [formData, setFormData] = useState({
    course: "",
    summary: "",
    learning_goals: [{ name: "", category: "", level_type: "", expected: "" }],
  });

  useEffect(() => {
    const fetchActiveCourses = async () => {
      try {
        const coursesData = await fetchCourses();
        const activeCourses = coursesData.filter((course) => course.active);
        setCourses(activeCourses);

        const levelsData = await fetchReportLevels();
        setReportLevels(levelsData);
      } catch (error) {
        console.error("Error fetching courses: ", error);
      }
    };

    fetchActiveCourses();
  }, []);

  // Handle form field changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle changes to learning goals
  const handleLearningGoalChange = (index, e) => {
    const { name, value } = e.target;
    const updatedGoals = [...formData.learning_goals];
    updatedGoals[index] = {
      ...updatedGoals[index],
      [name]: value,
    };
    setFormData({ ...formData, learning_goals: updatedGoals });
  };

  // Add new learning goal
  const addLearningGoal = () => {
    setFormData({
      ...formData,
      learning_goals: [
        ...formData.learning_goals,
        { name: "", category: "", level_type: "", expected: "" },
      ],
    });
  };

  // Remove a learning goal
  const removeLearningGoal = (index) => {
    const updatedGoals = formData.learning_goals.filter((_, i) => i !== index);
    setFormData({ ...formData, learning_goals: updatedGoals });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/report-cards/createReportCardTemplate`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create report card template");
      }

      toast.success("Report card template created successfully!");
      setFormData({
        course: "",
        summary: "",
        learning_goals: [
          { name: "", category: "", level_type: "", expected: "" },
        ],
      });
    } catch (error) {
      console.error("Error creating report card template: ", error);
      toast.error("Error creating report card template. Please try again.");
    }
  };

  return (
    <div className="create-report-card-template">
      <h2>Create Report Card Template</h2>
      <form onSubmit={handleSubmit}>
        {/* Course Dropdown Selection */}
        <div className="form-group">
          <label htmlFor="course">Course</label>
          <select
            id="course"
            name="course"
            value={formData.course}
            onChange={handleInputChange}
            className="form-control"
            required
          >
            <option value="">Select a Course</option>
            {courses.map((course) => (
              <option key={course._id} value={course._id}>
                {course.name}
              </option>
            ))}
          </select>
        </div>
        <hr></hr>
        {/* Summary Text Area */}
        <div className="form-group">
          <label htmlFor="summary">Summary</label>
          <textarea
            id="summary"
            name="summary"
            value={formData.summary}
            onChange={handleInputChange}
            className="form-control"
            rows="3"
            required
          ></textarea>
        </div>
        <hr></hr>
        {/* Learning Goals */}
        <div className="flex flex-space-between">
          <h3>Learning Goals</h3>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={addLearningGoal}
          >
            Add Learning Goal
          </button>
        </div>
        <hr></hr>
        <div className="learning-goals">
          {formData.learning_goals.map((goal, index) => (
            <div key={index} className="learning-goal">
              <input
                type="text"
                name="name"
                value={goal.name}
                onChange={(e) => handleLearningGoalChange(index, e)}
                placeholder="Goal Name"
                className="form-control"
                required
              />
              <input
                type="text"
                name="category"
                value={goal.category}
                onChange={(e) => handleLearningGoalChange(index, e)}
                placeholder="Category"
                className="form-control"
                required
              />
              <select
                name="level_type"
                value={goal.level_type}
                onChange={(e) => handleLearningGoalChange(index, e)}
                className="form-control-select"
                required
              >
                <option value="">Select Level Type</option>
                {["knowledge", "application", "skill"].map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              <select
                name="expected"
                value={goal.expected}
                onChange={(e) => handleLearningGoalChange(index, e)}
                className="form-control-select"
                required
                disabled={!goal.level_type}
              >
                <option value="">Select Expected Outcome</option>
                {goal.level_type &&
                  reportLevels[goal.level_type]?.map((level, i) => (
                    <option key={i} value={level}>
                      {level}
                    </option>
                  ))}
              </select>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => removeLearningGoal(index)}
              >
                Remove Goal
              </button>
            </div>
          ))}
        </div>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={addLearningGoal}
        >
          Add Learning Goal
        </button>
        <hr></hr>
        {/* Submit Button */}
        <button type="submit" className="btn btn-primary">
          Save Report Card Template
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default CreateReportCardTemplate;
