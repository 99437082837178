import React, { useState } from "react";
import CustomerView from "./CustomerView";
import CustomerList from "./CustomerList";
import CustomerSearch from "./CustomerSearch";

const Customers = () => {
  const [customerID, setCustomerID] = useState(null);

  const handleCustomerSearch = (id) => {
    setCustomerID(id);
  };

  const handleBackToList = () => {
    setCustomerID(null);
  };

  return (
    <div className="customers-container">
      {customerID ? (
        <div>
          <CustomerView customerID={customerID} onBack={handleBackToList} />
        </div>
      ) : (
        <div>
          <CustomerSearch onSearch={handleCustomerSearch} />
          <CustomerList onCustomerSelect={handleCustomerSearch} />
        </div>
      )}
    </div>
  );
};

export default Customers;
