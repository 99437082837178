const API_URL = process.env.REACT_APP_API_URL;

export const fetchCourses = async () => {
  const token = localStorage.getItem("token");

  const response = await fetch(`${API_URL}/api/courses`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    if (response.status === 401 || response.status === 403) {
      // Token might be expired or invalid
      console.error("Token expired or invalid, redirecting to login...");
      localStorage.removeItem("token"); // Remove the expired token

      // Redirect to login (if using React Router)
      window.location.href = "/"; // Redirect to login page
      return;
    }
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data;
};

export const addCourse = async (course) => {
  const token = localStorage.getItem("token");

  const response = await fetch(`${API_URL}/api/courses/new`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(course),
  });

  if (!response.ok) {
    const errorMessage = await response.text(); // Get more detailed error message
    throw new Error("Failed to add course: " + errorMessage);
  }

  return await response.json();
};
