// api/reportApi.js

const API_URL = process.env.REACT_APP_API_URL;

//----- GENERAL ------
// Fetch a general report template by its template name
export const fetchGeneralReportTemplate = async (templateName) => {
  try {
    // Get the token from localStorage for authentication
    const token = localStorage.getItem("token");
    const encodedTemplateName = encodeURIComponent(templateName);

    // Perform a GET request with the templateName as a query parameter
    const response = await fetch(
      `${API_URL}/api/report-cards/getGeneralReportTemplate?template_name=${encodedTemplateName}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    // Check if the response was successful
    if (!response.ok) {
      if (response.status === 404) {
        throw new Error("Template not found.");
      }
      if (response.status === 401 || response.status === 403) {
        // Handle expired or invalid token
        console.error("Token expired or invalid, redirecting to login...");
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return;
      }
      throw new Error(`Error fetching template: ${response.status}`);
    }

    // Parse and return the response data
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching general report template:", error);
    throw error;
  }
};

// Fetch a general report template by its template name
export const fetchGeneralReportTemplateID = async (templateID) => {
  try {
    // Get the token from localStorage for authentication
    const token = localStorage.getItem("token");

    // Perform a GET request with the templateName as a query parameter
    const response = await fetch(
      `${API_URL}/api/report-cards/getGeneralReportTemplateById/${templateID}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    // Check if the response was successful
    if (!response.ok) {
      if (response.status === 404) {
        throw new Error("Template not found.");
      }
      if (response.status === 401 || response.status === 403) {
        // Handle expired or invalid token
        console.error("Token expired or invalid, redirecting to login...");
        localStorage.removeItem("token");
        window.location.href = "/"; // Redirect to login page
        return;
      }
      throw new Error(`Error fetching template: ${response.status}`);
    }

    // Parse and return the response data
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching general report template:", error);
    throw error;
  }
};

// Fetch general report record
export const fetchGeneralReport = async (classId, instructorId, studentId) => {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${API_URL}/api/report-cards/getGeneralReport?classId=${classId}&instructorId=${instructorId}&studentId=${studentId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error fetching report: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error checking general report:", error);
    throw error;
  }
};

// Function to submit the report (ie; mid season update)
export const submitReport = async (reportData) => {
  const token = localStorage.getItem("token"); // Assume the token is saved in localStorage
  console.log(reportData);
  try {
    const response = await fetch(
      `${API_URL}/api/report-cards/createMidseasonReport`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Send the token in the header
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reportData), // Convert the report data to JSON
      }
    );

    if (!response.ok) {
      throw new Error("Error submitting report");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in submitReport:", error);
    throw error; // Optionally throw the error for further handling
  }
};

//----- REPORT CARDS ------

// Fecth report card template by course
export const fetchReportTemplate = async (courseId) => {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${API_URL}/api/report-cards/getReportTemplate?courseId=${courseId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      if (response.status === 404) {
        throw new Error(
          "Report Card Template for this course not yet available. Contact Eduardo Soto or John Krent for more info."
        );
      } else {
        throw new Error(`Error fetching report template: ${response.status}`);
      }
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching report template:", error.message);
    throw error;
  }
};

// Fetch levels that are used for knowledge, application, skill
export const fetchReportLevels = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${API_URL}/api/report-cards/getReportLevels`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`Error fetching report levels: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching report levels:", error);
    throw error;
  }
};

// Submit report card proper
export const submitReportCard = async (reportData) => {
  try {
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${API_URL}/api/report-cards/submitReportCard`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reportData),
      }
    );

    if (!response.ok) {
      throw new Error(`Error submitting report: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in submitReport:", error);
    throw error;
  }
};

// --- LEGACY ----
// Fetch report card templates
export const fetchLegacyReportCardTemplates = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token is missing");
    }

    const response = await fetch(
      `${API_URL}/api/report-cards/legacyTemplates`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Templates not found");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching report card templates:", error);
    throw error;
  }
};
