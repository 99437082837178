import React, { useState } from "react";
import "./StudentSearchModal.css"; // Add a CSS file for styling if needed

const StudentSearchModal = ({
  onClose,
  onAddStudent,
  searchStudents, // Function to search students
}) => {
  const [searchQuery, setSearchQuery] = useState({
    studentNum: "",
    firstName: "",
    lastName: "",
  });
  const [searchResults, setSearchResults] = useState([]);

  // Handle search query changes
  const handleSearchChange = (e) => {
    setSearchQuery({ ...searchQuery, [e.target.name]: e.target.value });
  };

  // Handle search for students
  const handleSearchStudents = async (e) => {
    e.preventDefault();
    try {
      const results = await searchStudents(searchQuery);
      setSearchResults(results);
    } catch (error) {
      console.error("Error searching for students: ", error);
    }
  };

  return (
    <div className="student-modal-overlay">
      <div className="student-modal-content">
        <h2>Search for Student</h2>
        <form onSubmit={handleSearchStudents}>
          <label>
            Student Number:
            <input
              type="text"
              name="studentNum"
              value={searchQuery.studentNum}
              onChange={handleSearchChange}
            />
          </label>
          <label>
            First Name:
            <input
              type="text"
              name="firstName"
              value={searchQuery.firstName}
              onChange={handleSearchChange}
            />
          </label>
          <label>
            Last Name:
            <input
              type="text"
              name="lastName"
              value={searchQuery.lastName}
              onChange={handleSearchChange}
            />
          </label>
          <button type="submit">Search</button>
        </form>

        {/* Display search results */}
        <ul>
          {searchResults.map((student) => (
            <li key={student._id} onClick={() => onAddStudent(student)}>
              {student.first_name} {student.last_name} ({student.student_num})
            </li>
          ))}
        </ul>

        <button className="close-modal" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default StudentSearchModal;
