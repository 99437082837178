import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Utility function to load an image and convert it to base64
const loadImageToBase64 = (url) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response); // Converts the image to base64
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });
};

export const generateLegacyReportCardPDF = async (reportCard, template) => {
  console.log(reportCard);
  const companyLogo = await loadImageToBase64("/logo.png");
  const rowsContent = template.rows.map((row, index) => {
    const [criteria, exemplary, accomplished, developing, beginning] =
      row.split("|");
    const grade = reportCard.row_ratings[index];

    return [
      { text: criteria, fontSize: 8 }, // First column: Criteria
      { text: exemplary, fontSize: 8 }, // Second column: Exemplary
      { text: accomplished, fontSize: 8 }, // Third column: Accomplished
      { text: developing, fontSize: 8 }, // Fourth column: Developing
      { text: beginning, fontSize: 8 }, // Fifth column: Beginning
      { text: grade || "No grade", fontSize: 8 }, // Sixth column: Grade Achieved
    ];
  });

  const expectationsContent = template.expectations
    .split("|")
    .map((expectation) => ({
      text: `• ${expectation}`,
      margin: [0, 5],
      fontSize: 10,
    }));

  const docDefinition = {
    content: [
      // Header with Logo and Title
      {
        columns: [
          {
            // Left side: Title and Course Information
            stack: [
              { text: "Report Card", style: "header" },
              { text: `Course: ${template.name}`, style: "subheader" },
              { text: `Student: ${reportCard.student_name}`, margin: [0, 5] },
              {
                text: `Instructor: ${reportCard.instructor_name}`,
                margin: [0, 5],
              },
            ],
            width: "70%", // Adjust this to control the width of the left content
          },
          {
            stack: [
              {
                image: companyLogo, // Base64 encoded image
                width: 100, // Adjust the logo size
                alignment: "right",
              },
              {
                text: `Date:    ${new Date(
                  reportCard.report_date
                ).toLocaleDateString()}`,
                margin: [0, 15],
                alignment: "right",
              },
            ],
          },
        ],
      },
      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
        margin: [0, 0],
      },
      { text: template.description, margin: [0, 10], fontSize: 10 },
      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
        margin: [0, 0],
      },
      { text: "Expectations", style: "subheader", margin: [0, 10] },
      ...expectationsContent,

      // Add a horizontal line and space before the table
      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
        margin: [0, 5],
      },
      {
        table: {
          headerRows: 1,
          widths: ["17%", "17%", "17%", "17%", "17%", "15%"], // Adjust column widths
          body: [
            // Table headers
            [
              { text: "Criteria", bold: true, fontSize: 10 },
              { text: "Exemplary", bold: true, fontSize: 10 },
              { text: "Accomplished", bold: true, fontSize: 10 },
              { text: "Developing", bold: true, fontSize: 10 },
              { text: "Beginning", bold: true, fontSize: 10 },
              { text: "Assessment", bold: true, fontSize: 10 },
            ],
            ...rowsContent, // Table rows with criteria and descriptions
          ],
          dontBreakRows: true, // Prevent rows from breaking across pages
          keepWithHeaderRows: true, // Ensure rows stay together with headers
        },
        layout: "lightHorizontalLines", // Optional: adds lines between rows
        margin: [0, 10], // Adds space above the table
      },
      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
        margin: [0, 0],
      },
      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
        margin: [0, 10],
      },
      { text: "Instructor Comments", style: "subheader", margin: [0, 10] },
      { text: reportCard.instructor_comments, margin: [0, 5], fontSize: 12 },
      {
        canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
        margin: [0, 10],
      },
      { text: "Next Recommended Course", style: "subheader", margin: [0, 8] },
      { text: reportCard.next_recommended, margin: [0, 8], fontSize: 14 },
      {
        text: "To learn about your next recommended course visit: ",
        fontSize: 10,
        alignment: "left",
      },
      {
        text: "www.realprogramming.com",
        link: "http://www.realprogramming.com",
        color: "blue",
        fontSize: 10,
        alignment: "left",
        decoration: "underline",
      },
    ],
    footer: (currentPage, pageCount) => {
      return {
        columns: [
          {
            text: `Real Programming 4 Kids\n255 Glenlake Ave #1511, Toronto, ON, M6P 1G2\n1-877-307-3456 (toll free) / 416-469-9676 (Toronto)`,
            alignment: "center",
            fontSize: 10,
          },
        ],
        margin: [0, 0, 0, 20], // Adjust the margin to move footer away from the bottom edge
      };
    },
    styles: {
      header: {
        fontSize: 22,
        bold: true,
      },
      subheader: {
        fontSize: 14,
        bold: true,
      },
    },
  };

  pdfMake.createPdf(docDefinition).download("report_card.pdf");
};
