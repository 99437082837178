// src/components/CustomerForm.js
import React, { useState, useEffect } from "react";
import { fetchStudentsByCustomerNum, saveStudent } from "../../api/studentApi";
import { toast } from "react-toastify"; // Import toast for showing messages

import "./CustomerForm.css"; // Create a new CSS file to style the form

const CustomerForm = ({
  customer,
  onSubmit,
  onClose,
  onDelete,
  nextCustomerID,
}) => {
  // Initialize the form data with default values, or prepopulate if in edit mode
  const [formData, setFormData] = useState({
    CustomerID: "",
    home_phone: "",
    primary_first_name: "",
    primary_last_name: "",
    cust_rel: "",
    cell_phone: "",
    sec_first_name: "",
    sec_last_name: "",
    sec_rel: "",
    sec_phone: "",
    street_num: "",
    street_name: "",
    postal_code: "",
    city: "",
    country: "",
    prov_code: "",
    qbo_boolean: false,
    qbo_date: "",
    email_address: "",
    date_contact: "",
    how_found: "",
    sec_email_address: "",
    comment: "",
    rep_id: "",
  });
  const [students, setStudents] = useState([]);

  useEffect(() => {
    if (customer) {
      setFormData(customer);
      async function fetchStudents(customerID) {
        const response = await fetchStudentsByCustomerNum(customerID);
        setStudents(response);
        return response;
      }
      fetchStudents(customer.CustomerID);
    } else if (nextCustomerID) {
      // If adding a new customer, auto-populate the CustomerID field
      setFormData((prevFormData) => ({
        ...prevFormData,
        CustomerID: nextCustomerID,
      }));
    }
  }, [customer, nextCustomerID]);

  const handleStudentCommentChange = (studentIndex, commentIndex, value) => {
    const updatedStudents = [...students];
    updatedStudents[studentIndex].student_comments[commentIndex] = {
      ...updatedStudents[studentIndex].student_comments[commentIndex],
      comment: value,
    };
    setStudents(updatedStudents);
  };

  const handleAddComment = (studentIndex) => {
    const updatedStudents = [...students];
    if (!updatedStudents[studentIndex].student_comments) {
      updatedStudents[studentIndex].student_comments = [];
    }
    updatedStudents[studentIndex].student_comments.push({
      comment: "",
      author: "Instructor", // Default author, can be changed if necessary
    });
    setStudents(updatedStudents);
  };

  const handleRemoveComment = (studentIndex, commentIndex) => {
    const updatedStudents = [...students];
    updatedStudents[studentIndex].student_comments.splice(commentIndex, 1);
    setStudents(updatedStudents);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value || "",
    });
  };

  const handleStudentChange = (index, e) => {
    const { name, value } = e.target;
    const updatedStudents = [...students];
    updatedStudents[index] = {
      ...updatedStudents[index],
      [name]: value,
    };
    setStudents(updatedStudents);
  };

  // Add a new student section
  const handleAddStudent = () => {
    setStudents([
      ...students,
      { first_name: "", last_name: "", dob: "", age: "", student_comments: [] },
    ]);
  };

  // Remove a student section
  const handleRemoveStudent = (index) => {
    const updatedStudents = students.filter((_, i) => i !== index);
    setStudents(updatedStudents);
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      const result = await onDelete(customer.CustomerID); // Await the delete action

      if (result.success) {
        toast.success("Customer deleted successfully!");
        onClose(); // Close the modal only if the deletion was successful
      } else {
        toast.error("Failed to delete customer: " + result.error);
        console.error("Error deleting customer:", result.error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Step 1: Save Customer
    const result = await onSubmit(formData, customer);

    if (result.success) {
      toast.success("Customer saved successfully!");
      // Step 2: Save Students for the Customer
      const promises = students.map(async (student) => {
        // If customer exists, link it with the student's customer_num
        student.customer_num = formData.CustomerID;

        // Save the student
        const studentResult = await saveStudent(student);
        return studentResult;
      });

      // Wait for all student save operations to complete
      const studentResults = await Promise.all(promises);

      // Check if any student save failed
      const failedStudentResults = studentResults.filter((res) => !res.success);
      if (failedStudentResults.length > 0) {
        toast.error("Failed to save one or more students.");
        console.error("Student save errors:", failedStudentResults);
        return; // Do not close the modal if there were issues saving students
      }

      onClose(); // Close the modal if all submissions are successful
    } else {
      toast.error("Failed to save customer: " + result.error);
    }
  };

  return (
    <div className="customer-form-container">
      <div className="customer-form-heading">
        {customer ? "Edit Customer" : "Add New Customer"}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="form-group col-3">
            <label>Customer ID</label>
            <input
              type="number"
              name="CustomerID"
              value={formData.CustomerID || ""}
              onChange={handleInputChange}
              required
              disabled={!!customer} // Disable editing if editing an existing customer
            />
          </div>
          <div className="form-group col-3">
            <label>Email Address</label>
            <input
              type="email"
              name="email_address"
              value={formData.email_address || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-3">
            <label>Secondary Email Address</label>
            <input
              type="email"
              name="sec_email_address"
              value={formData.sec_email_address || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-3">
            <label>Home Phone</label>
            <input
              type="tel"
              name="home_phone"
              value={formData.home_phone || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-3">
            <label>Primary First Name</label>
            <input
              type="text"
              name="primary_first_name"
              value={formData.primary_first_name || ""}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group col-3">
            <label>Primary Last Name</label>
            <input
              type="text"
              name="primary_last_name"
              value={formData.primary_last_name || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-3">
            <label>Customer Relationship</label>
            <input
              type="text"
              name="cust_rel"
              value={formData.cust_rel || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-3">
            <label>Cell Phone</label>
            <input
              type="tel"
              name="cell_phone"
              value={formData.cell_phone || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-3">
            <label>Secondary First Name</label>
            <input
              type="text"
              name="sec_first_name"
              value={formData.sec_first_name || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-3">
            <label>Secondary Last Name</label>
            <input
              type="text"
              name="sec_last_name"
              value={formData.sec_last_name || ""}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group col-3">
            <label>Secondary Relationship</label>
            <input
              type="text"
              name="sec_rel"
              value={formData.sec_rel || ""}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group col-3">
            <label>Secondary Phone</label>
            <input
              type="tel"
              name="sec_phone"
              value={formData.sec_phone || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <hr />
        <div className="form-heading">Address</div>
        <div className="row">
          <div className="form-group col-2">
            <label>Street Number</label>
            <input
              type="text"
              name="street_num"
              value={formData.street_num || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-6">
            <label>Street Name</label>
            <input
              type="text"
              name="street_name"
              value={formData.street_name || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-4">
            <label>City</label>
            <input
              type="text"
              name="city"
              value={formData.city || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-2">
            <label>Province Code</label>
            <input
              type="text"
              name="prov_code"
              value={formData.prov_code || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-4">
            <label>Country</label>
            <input
              type="text"
              name="country"
              value={formData.country || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-3">
            <label>Postal Code</label>
            <input
              type="text"
              name="postal_code"
              value={formData.postal_code || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="form-group col-3">
            <label>Rep ID</label>
            <input
              type="text"
              name="rep_id"
              value={formData.rep_id || ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-3">
            <label>Date of Contact</label>
            <input
              type="date"
              name="date_contact"
              value={
                formData.date_contact ? formData.date_contact.split("T")[0] : ""
              }
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-3">
            <label>QBO Date</label>
            <input
              type="date"
              name="qbo_date"
              value={formData.qbo_date ? formData.qbo_date.split("T")[0] : ""}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-3">
            <label>QBO Added</label>
            <input
              type="checkbox"
              name="qbo_boolean"
              checked={formData.qbo_boolean || ""}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className="form-group">
          <label>How Did You Find Us?</label>
          <input
            type="text"
            name="how_found"
            value={formData.how_found || ""}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Comments</label>
          <textarea
            name="comment"
            value={formData.comment || ""}
            onChange={handleInputChange}
            rows="3"
          />
        </div>
        {/* Student Sections */}
        <div className="form-heading">Students</div>
        {students.map((student, studentIndex) => (
          <div key={studentIndex} className="student-section">
            <div className="row">
              <div className="form-group col-3">
                <label>Student Number</label>
                <input
                  type="text"
                  name="student_num"
                  value={student.student_num || ""}
                  onChange={(e) => handleStudentChange(studentIndex, e)}
                />
              </div>
              <div className="form-group col-7">
                <label></label>
                <p>
                  <i>
                    Leave <b>Student Number</b> empty for new student
                  </i>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-3">
                <label>First Name</label>
                <input
                  type="text"
                  name="first_name"
                  value={student.first_name || ""}
                  onChange={(e) => handleStudentChange(studentIndex, e)}
                  required
                />
              </div>
              <div className="form-group col-3">
                <label>Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={student.last_name || ""}
                  onChange={(e) => handleStudentChange(studentIndex, e)}
                  required
                />
              </div>
              <div className="form-group col-3">
                <label>Date of Birth</label>
                <input
                  type="date"
                  name="dob"
                  value={student.dob ? student.dob.split("T")[0] : ""}
                  onChange={(e) => handleStudentChange(studentIndex, e)}
                />
              </div>
              <div className="form-group col-3">
                <label>Age</label>
                <input
                  type="text"
                  name="age"
                  value={student.age || ""}
                  onChange={(e) => handleStudentChange(studentIndex, e)}
                />
              </div>
            </div>
            <div className="row">
              {student.student_comments &&
                student.student_comments.map((comment, commentIndex) => (
                  <div key={commentIndex} className="row align-items-center">
                    <div className="form-group col-9">
                      <label>Comment</label>
                      <input
                        type="text"
                        value={comment.comment || ""}
                        onChange={(e) =>
                          handleStudentCommentChange(
                            studentIndex,
                            commentIndex,
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="form-group col-3">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() =>
                          handleRemoveComment(studentIndex, commentIndex)
                        }
                      >
                        Remove Comment
                      </button>
                    </div>
                  </div>
                ))}
              <div>
                {/* <div className="form-group col-6"> */}
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleAddComment(studentIndex)}
                >
                  + Add Comment
                </button>
                {/* </div> */}
                {/* <div className="form-group col-6"> */}
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleRemoveStudent(studentIndex)}
                >
                  Remove Student
                </button>
                {/* </div> */}
              </div>
            </div>
            <hr />
          </div>
        ))}
        <button
          type="button"
          className="btn btn-secondary"
          onClick={handleAddStudent}
        >
          + Add Student
        </button>
        <hr />
        <button type="submit" className="btn btn-primary">
          {customer ? "Update Customer" : "Create Customer"}
        </button>
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          Cancel
        </button>
        {customer && (
          <button
            type="button"
            className="btn btn-danger"
            onClick={handleDelete}
          >
            Delete Customer
          </button>
        )}
      </form>
    </div>
  );
};

export default CustomerForm;
