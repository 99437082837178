import React from "react";
import Modal from "react-modal";
import "./ReusableModal.css";

const ReusableModal = ({ isOpen, onClose, content }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Modal"
      className="reusable-modal"
      appElement={document.getElementById("root")} // Fix for accessibility warning
      style={{
        overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        content: { maxWidth: "1000px", margin: "auto" },
      }}
    >
      {content}
    </Modal>
  );
};

export default ReusableModal;
