// src/components/StudentSearch.js
import React, { useState } from "react";
import { fetchStudentByNumber } from "../../api/studentApi";
import { fetchClassesByStudentId } from "../../api/classApi";
import { fetchLegacyReportCardTemplates } from "../../api/reportApi";
import "./StudentSearch.css";
import { generateLegacyReportCardPDF } from "../../pdf/generateLegacyReportCard"; // Import the PDF generation function

const StudentSearch = () => {
  const [studentNum, setStudentNum] = useState("");
  const [studentData, setStudentData] = useState(null);
  const [classesData, setClassesData] = useState([]);
  const [reportCardTemplates, setReportCardTemplates] = useState([]);
  const [error, setError] = useState("");

  const handleSearch = async () => {
    try {
      const data = await fetchStudentByNumber(studentNum);
      console.log(data);
      setStudentData(data);
      setError(null);
      const classes = await fetchClassesByStudentId(data._id);
      setClassesData(classes);
      const templates = await fetchLegacyReportCardTemplates();
      setReportCardTemplates(templates);
    } catch (error) {
      setError(error.message);
      setStudentData(null);
      setClassesData(null);
    }
  };

  // Function to handle PDF generation
  const handleDownloadReportCard = (reportCard) => {
    // Find the matching template for the report card based on the course ID
    const matchedTemplate = reportCardTemplates.find(
      (template) => template.course._id === reportCard.course._id
    );
    if (matchedTemplate) {
      generateLegacyReportCardPDF(reportCard, matchedTemplate); // Generate the PDF
    } else {
      console.error("No matching template found for the report card.");
    }
  };

  return (
    <div className="student-search-container">
      <h2 className="student-search">Student Search</h2>
      <input
        type="text"
        placeholder="Enter student_num"
        value={studentNum}
        onChange={(e) => setStudentNum(e.target.value)}
        className="student-search-input"
      />
      <button className="student-search-button" onClick={handleSearch}>
        Search
      </button>
      <hr />
      {error && <p className="error-message">{error}</p>}
      {studentData && (
        <div className="student-view-info">
          <h3>
            Student Name: {studentData.first_name} {studentData.last_name}
          </h3>
          <p>
            <strong>Student Number:</strong> {studentData.student_num}
          </p>

          {studentData.next_recommended_course &&
            studentData.next_recommended_course.length > 0 && (
              <div className="next-recommended">
                <strong>Next Course Recommendations:</strong>
                <ul>
                  {studentData.next_recommended_course.map((course, index) => (
                    <li key={index}>{course.name}</li>
                  ))}
                </ul>
              </div>
            )}

          <hr />
          <div className="courses-taken">
            <h4>Courses Taken:</h4>
            {classesData && classesData.length > 0 ? (
              <ul>
                {classesData.map((courseClass, index) => (
                  <li key={index}>
                    <strong>Course Name:</strong> {courseClass.course.name}{" "}
                    <br />
                    <strong>Instructor:</strong> {courseClass.instructor_name}{" "}
                    <br />
                    <strong>Season:</strong> {courseClass.season}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No courses found for this student.</p>
            )}
          </div>
          <hr />
          <div className="student-comments">
            <h4>Student Comments:</h4>
            {studentData.student_comments &&
            studentData.student_comments.length > 0 ? (
              <ul>
                {studentData.student_comments
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .map((comment, index) => (
                    <li key={index}>
                      {comment.comment} (Date:{" "}
                      {new Date(comment.date).toLocaleDateString()} )
                    </li>
                  ))}
              </ul>
            ) : (
              <p>No comments found for this student.</p>
            )}
          </div>
          <hr />
          <div className="instructor-comments">
            <h4>Instructor Comments:</h4>
            {studentData.report_cards && studentData.report_cards.length > 0 ? (
              <ul>
                {studentData.report_cards
                  .sort(
                    (a, b) => new Date(b.report_date) - new Date(a.report_date)
                  )
                  .map((reportCard, index) => (
                    <li key={index}>
                      <div className="course-container">
                        <div className="course-info">
                          <b>Course:</b> {reportCard.course.name}
                          <br />
                          <b>
                            Report Date:{" "}
                            {new Date(
                              reportCard.report_date
                            ).toLocaleDateString()}
                          </b>
                        </div>
                        <button
                          className="download-report-button"
                          onClick={() => handleDownloadReportCard(reportCard)}
                        >
                          Download Report Card
                        </button>
                      </div>

                      <hr />
                      {reportCard.instructor_comments}
                      <br />
                    </li>
                  ))}
              </ul>
            ) : (
              <p>No instructor comments found for this student.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentSearch;
