import React, { useState, useContext } from "react";
import Modal from "react-modal";
import ReusableModal from "../General/ReusableModal";
import { UserContext } from "../../context/UserContext";
import { FaPen, FaCheckCircle } from "react-icons/fa";
import { toast } from "react-toastify";
import ReportCard from "../ReportCards/ReportCard";
import MidSeasonReportForm from "../ReportCards/MidSeasonReport";
import "./DisplayClass.css";

Modal.setAppElement("#root");

const DisplayClass = ({ classItem, onEdit, onRefreshClasses }) => {
  const [isNotesExpanded, setIsNotesExpanded] = useState(false);
  const { userRole } = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const toggleNotes = () => {
    setIsNotesExpanded((prev) => !prev);
  };

  const handleAlreadySubmitted = (type) => {
    toast.info(`Already submitted ${type}`);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const handleReportCardClick = (studentItem) => {
    setModalContent(() => (
      <ReportCard
        course={classItem.course}
        student={studentItem.student}
        classItem={classItem}
        onClose={() => {
          handleModalClose();
          onRefreshClasses(); // Refresh classes after submission
        }}
      />
    ));
    setIsModalOpen(true);
  };

  const handleMidSeasonUpdateClick = (studentItem) => {
    setModalContent(() => (
      <MidSeasonReportForm
        studentId={studentItem.student._id}
        classId={classItem._id}
        onClose={() => {
          handleModalClose();
          onRefreshClasses(); // Refresh classes after submission
        }}
      />
    ));
    setIsModalOpen(true);
  };

  return (
    <div className="class-info">
      <div className="class-info-header">
        <p className="class-info-item">{classItem.class_code}</p>
        <p className="class-info-item">
          {classItem.instructor?.name || "Not Assigned"}
        </p>
        <p className="class-info-item">
          {classItem.course?.name || "Not Assigned"}
        </p>
        {userRole === 8 && (
          <button
            className="edit-icon-button"
            onClick={() => onEdit(classItem)}
          >
            <FaPen className="edit-icon" />
          </button>
        )}
      </div>
      <div className="class-details-container">
        <div className="details-section">
          <strong>Season:</strong> {classItem.season}
          <br />
          <strong>Time:</strong> {classItem.from_time} - {classItem.to_time}
          <br />
          <strong>Start Date:</strong>{" "}
          {new Date(classItem.start_date).toISOString().split("T")[0]}
          <br />
          <strong>End Date:</strong>{" "}
          {new Date(classItem.end_date).toISOString().split("T")[0]}
          <br />
          <a href={classItem.course_link} target="_blank" rel="noreferrer">
            <strong>Course Link</strong>
          </a>{" "}
          -{" "}
          <a href={classItem.zoom_link} target="_blank" rel="noreferrer">
            <strong>Zoom Link</strong>
          </a>
        </div>
        <div className="students-section">
          {classItem.students && classItem.students.length > 0 ? (
            <table className="students-table">
              <thead>
                <tr>
                  <th>Student Information</th>
                  {userRole === 4 && <th>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {classItem.students.map((studentItem, index) =>
                  studentItem?.student ? (
                    <tr key={`${studentItem.student._id}-${index}`}>
                      <td>
                        {studentItem.student.first_name}{" "}
                        {studentItem.student.last_name} (
                        {studentItem.student.student_num})
                      </td>
                      {userRole === 4 && (
                        <td>
                          {/* Report Card Action or Checkmark */}
                          {studentItem.submitted_reportcard ? (
                            <FaCheckCircle
                              className="checkmark-icon"
                              title="Report Card already submitted"
                              onClick={() =>
                                handleAlreadySubmitted("Report Card")
                              }
                            />
                          ) : (
                            <span
                              className="action-icon"
                              title="Report Card"
                              onClick={() => handleReportCardClick(studentItem)}
                            >
                              📑
                            </span>
                          )}

                          {/* Mid-Season Update Action or Checkmark */}
                          {studentItem.submitted_midseasonupdate ? (
                            <FaCheckCircle
                              className="checkmark-icon"
                              title="Mid-Season Update already submitted"
                              onClick={() =>
                                handleAlreadySubmitted("Mid-Season Update")
                              }
                            />
                          ) : (
                            <span
                              className="action-icon"
                              title="Mid-Season Update"
                              onClick={() =>
                                handleMidSeasonUpdateClick(studentItem)
                              }
                            >
                              📄
                            </span>
                          )}
                        </td>
                      )}
                    </tr>
                  ) : (
                    <tr key={`missing-${index}`}>
                      <td>Student details not available</td>
                      <td>-</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          ) : (
            <p>No students enrolled</p>
          )}
        </div>
      </div>
      <div className="notes-section">
        <strong className="expandable-title" onClick={toggleNotes}>
          Class Notes {isNotesExpanded ? "▲" : "▼"}
        </strong>
        {isNotesExpanded &&
        classItem.class_updates &&
        classItem.class_updates.length > 0 ? (
          <ul>
            {classItem.class_updates
              .slice() // Create a copy to avoid mutating the original array
              .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date in ascending order
              .map((classUpdate, index) => (
                <li key={`${classUpdate.date}-${index}`}>
                  <strong>
                    {new Date(classUpdate.date).toISOString().split("T")[0]}
                  </strong>{" "}
                  | {classUpdate.update}
                  <hr
                    style={{
                      borderColor: "#ddd",
                      borderWidth: "1px",
                      borderStyle: "dashed",
                    }}
                  ></hr>
                </li>
              ))}
          </ul>
        ) : (
          isNotesExpanded && <p>No notes have been added.</p>
        )}
      </div>

      <ReusableModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        content={modalContent}
      />
    </div>
  );
};

export default DisplayClass;
