// src/components/CustomerSearch.js
import React, { useState } from "react";
import "./CustomerSearch.css"; // CSS specific to this component
const API_URL = process.env.REACT_APP_API_URL;

const CustomerSearch = ({ onSearch }) => {
  const [customerId, setCustomerId] = useState("");
  const [error, setError] = useState("");

  const handleSearch = async () => {
    if (!customerId) {
      setError("Please enter a Customer ID");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authentication token is missing");
      }
      const response = await fetch(
        `${API_URL}/api/customers/find?customerID=${customerId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Customer not found");
      }
      const data = await response.json();
      setError("");
      console.log(data);
      onSearch(data.CustomerID);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="customer-search-container">
      <h2>Search Customer</h2>
      <input
        type="text"
        placeholder="Enter Customer ID"
        value={customerId}
        onChange={(e) => setCustomerId(e.target.value)}
        className="customer-search-input"
      />
      <button className="customer-search-button" onClick={handleSearch}>
        Search
      </button>
      {error && <p className="customer-search-error">{error}</p>}
    </div>
  );
};

export default CustomerSearch;
