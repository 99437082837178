const API_URL = process.env.REACT_APP_API_URL;

// Fetch all students
export const fetchStudents = async (token) => {
  const response = await fetch(`${API_URL}/api/students`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`Error fetching students: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

// Fetch all instructors (users with role = 4)
export const fetchInstructors = async (token) => {
  const response = await fetch(`${API_URL}/api/users/instructors`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`Error fetching instructors: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

// Fetch all courses
export const fetchCourses = async (token) => {
  const response = await fetch(`${API_URL}/api/courses`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`Error fetching courses: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

// Fetch classes by student ID
export const fetchClassesByStudentId = async (studentId) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token is missing");
    }

    const response = await fetch(
      `${API_URL}/api/class/student?student_id=${studentId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Classes not found for student");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching classes:", error);
    throw error;
  }
};

// Submit new class data
export const submitClassData = async (classData, token) => {
  const response = await fetch(`${API_URL}/api/class/createClass`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(classData), // Convert the class data into JSON format
  });

  if (!response.ok) {
    throw new Error(`Error submitting class: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

// Search students by student number or name
export const searchStudents = async (query) => {
  const token = localStorage.getItem("token");
  const params = new URLSearchParams(query).toString(); // Convert query object to URL query string
  const response = await fetch(`${API_URL}/api/students/search?${params}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`Error searching students: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

// Fetch all classes
export const fetchClasses = async (token) => {
  const response = await fetch(`${API_URL}/api/class`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error(`Error fetching classes: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const updateMidseasonOrReport = async (
  classId,
  studentId,
  updateType
) => {
  try {
    const token = localStorage.getItem("token"); // Get the auth token

    const response = await fetch(
      `${API_URL}/api/class/updateMidseasonAndReport`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ classId, studentId, updateType }),
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error updating student report status: ${response.status}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating student report status:", error);
    throw error;
  }
};

// Update class data
export const updateClassData = async (classData, token) => {
  console.log(classData);
  const response = await fetch(`${API_URL}/api/class/${classData._id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(classData),
  });

  if (!response.ok) {
    throw new Error(`Error updating class: ${response.status}`);
  }

  const data = await response.json();
  return data;
};

export const deleteClass = async (classId, token) => {
  try {
    const response = await fetch(`${API_URL}/api/class/delete/${classId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add this line if you need token-based authentication
      },
    });

    if (!response.ok) {
      throw new Error("Failed to delete the class");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error deleting class:", error);
    throw error;
  }
};
