// src/components/HomePage.js
import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import DisplayClasses from "../Classes/DisplayClasses";

const HomePage = () => {
  const { userRole, userID } = useContext(UserContext);

  // Define what to render based on user role
  const renderContentForRole = () => {
    switch (userRole) {
      case 8:
        return (
          <div>
            <h2>Admin Dashboard</h2>
            <p>Welcome, Admin! Here are your admin options.</p>
            {/* You can add buttons or links to admin-related pages here */}
          </div>
        );
      case 4:
        return (
          <div>
            <h2>Instructor Dashboard</h2>
            <DisplayClasses instructorID={userID} userRole={userRole} />
            {/* Add more actions that an instructor can do */}
          </div>
        );
      default:
        return (
          <div>
            <h2>Welcome to Our Application</h2>
            <p>Please log in to continue.</p>
          </div>
        );
    }
  };

  return <div className="home-page">{renderContentForRole()}</div>;
};

export default HomePage;
