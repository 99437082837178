import React, { useState, useEffect } from "react";
import { fetchStudents } from "../../api/studentApi";

const StudentList = ({ onStudentSelect }) => {
  const [students, setStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const limit = 100; // Limit the number of students per page

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true at the beginning of fetch
      try {
        const data = await fetchStudents(currentPage, limit);
        setStudents(data.students);
        setTotalPages(data.totalPages); // Set total pages for pagination
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Call the async function to fetch the students
  }, [currentPage]);

  // Handle pagination button clicks
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) {
    return <p>Loading students...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <h2>Student List</h2>
      {/* Pagination buttons */}
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
      <ul>
        {students.map((student) => (
          <li key={student.student_num}>
            <button onClick={() => onStudentSelect(student.student_num)}>
              {student.first_name} {student.last_name} (Student Num:{" "}
              {student.student_num})
            </button>

            {student.report_cards && student.report_cards.length > 0 && (
              <span> - {student.report_cards.length} Report Cards</span>
            )}
          </li>
        ))}
      </ul>

      {/* Pagination buttons */}
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default StudentList;
