import React, { useEffect, useState } from "react";
import { fetchCourses, addCourse } from "../api/courseApi";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CourseList.css";
const API_URL = process.env.REACT_APP_API_URL;

const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCourses = async () => {
      try {
        const data = await fetchCourses();
        const sortedCourses = data.sort((a, b) => a.list_order - b.list_order);
        setCourses(sortedCourses);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getCourses();
  }, []);

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(courses);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedCourses = items.map((course, index) => ({
      ...course,
      list_order: index,
    }));

    setCourses(updatedCourses);

    try {
      const token = localStorage.getItem("token");

      const updatePromises = updatedCourses.map((course) =>
        fetch(`${API_URL}/api/courses/update/${course._id}`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,

            "Content-Type": "application/json",
          },
          body: JSON.stringify({ list_order: course.list_order }),
        })
      );
      await Promise.all(updatePromises);
    } catch (error) {
      setError("Failed to update list order: " + error.message);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedCourses = [...courses];
    updatedCourses[index][field] = value;
    setCourses(updatedCourses);
  };

  const handleSave = async (course) => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        `${API_URL}/api/courses/update/${course._id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(course),
        }
      );

      if (response.ok) {
        toast.success("Course updated successfully!", { autoClose: 1000 });
      } else {
        throw new Error("Failed to update the course");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSaveAll = async () => {
    try {
      const token = localStorage.getItem("token");

      // Loop through all courses and send updates to the server
      const updatePromises = courses.map((course) =>
        fetch(`${API_URL}/api/courses/update/${course._id}`, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(course), // Send the updated course data
        })
      );

      // Wait for all the updates to complete
      await Promise.all(updatePromises);

      // Show success notification
      toast.success("All courses updated successfully!", { autoClose: 3000 });
    } catch (err) {
      // Show error notification
      toast.error("Error updating courses: " + err.message, {
        autoClose: 5000,
      });
    }
  };

  const handleAddCourse = async () => {
    const newCourse = {
      name: "New Course",
      level: "Beginner",
      language: "Java",
      software: "",
      course_code: "",
      active: false,
      list_order: courses.length,
    };

    try {
      const addedCourse = await addCourse(newCourse);
      setCourses([...courses, addedCourse]);
    } catch (err) {
      setError("Failed to add new course: " + err.message);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="course-list">
      <h2>Course List</h2>
      <button onClick={handleAddCourse}>Add New Course</button>
      <button onClick={handleSaveAll}>Save All</button>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <table {...provided.droppableProps} ref={provided.innerRef}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Level</th>
                  <th>Language</th>
                  <th>Software</th>
                  <th>Course Code</th>
                  <th>Active</th>
                  <th>Actions</th>
                  <th>Drag</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course, index) => (
                  <Draggable
                    key={course._id || index}
                    draggableId={course._id || `new-${index}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`${
                          snapshot.isDragging ? "dragging" : "draggable"
                        } ${
                          course.active ? "active-course" : "inactive-course"
                        }`}
                      >
                        <td>
                          <input
                            type="text"
                            value={course.name}
                            onChange={(e) =>
                              handleInputChange(index, "name", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={course.level}
                            onChange={(e) =>
                              handleInputChange(index, "level", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={course.language}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "language",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={course.software}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "software",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={course.course_code}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "course_code",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={course.active}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "active",
                                e.target.checked
                              )
                            }
                          />
                        </td>
                        <td>
                          <button onClick={() => handleSave(course)}>
                            Save
                          </button>
                        </td>
                        <td
                          {...provided.dragHandleProps}
                          style={{ cursor: "grab" }}
                        >
                          &#8597;
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CourseList;
