import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./CreateGeneralReportTemplate.css";

const API_URL = process.env.REACT_APP_API_URL;

const CreateGeneralReportTemplate = () => {
  const [templateName, setTemplateName] = useState("");
  const [sections, setSections] = useState([]);

  // Add a new section to the sections array
  const addSection = () => {
    setSections([...sections, { section_name: "", options: [""] }]);
  };

  // Handle input change for a specific section
  const handleSectionChange = (index, value) => {
    const updatedSections = [...sections];
    updatedSections[index].section_name = value;
    setSections(updatedSections);
  };

  // Add a new option to a specific section
  const addOption = (index) => {
    const updatedSections = [...sections];
    updatedSections[index].options.push("");
    setSections(updatedSections);
  };

  // Remove a specific option from a section
  const removeOption = (sectionIndex, optionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].options.splice(optionIndex, 1);
    setSections(updatedSections);
  };

  // Handle input change for an option in a specific section
  const handleOptionChange = (sectionIndex, optionIndex, value) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].options[optionIndex] = value;
    setSections(updatedSections);
  };

  // Remove a specific section
  const removeSection = (sectionIndex) => {
    const updatedSections = [...sections];
    updatedSections.splice(sectionIndex, 1);
    setSections(updatedSections);
  };

  // Handle save button click
  const handleSave = async () => {
    if (templateName.trim() === "" || sections.length === 0) {
      toast.error("Please enter a template name and at least one section.");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${API_URL}/api/report-cards/generalReportTemplate`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ template_name: templateName, sections }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error creating template: ${response.status}`);
      }

      const data = await response.json();

      toast.success("Template created successfully!");
      console.log("Created template:", data);
    } catch (error) {
      console.error("Error creating template:", error);
      toast.error("Failed to create template. Please try again.");
    }
  };

  return (
    <div className="create-general-report-template">
      <h2>Create General Report Template</h2>

      {/* Input for Template Name */}
      <div className="form-group">
        <label htmlFor="template_name">Template Name</label>
        <input
          type="text"
          id="template_name"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
        />
      </div>

      {/* Sections */}
      {sections.map((section, sectionIndex) => (
        <div key={sectionIndex} className="section">
          <div className="form-group">
            <label htmlFor={`section_name_${sectionIndex}`}>Section Name</label>
            <input
              type="text"
              id={`section_name_${sectionIndex}`}
              value={section.section_name}
              onChange={(e) =>
                handleSectionChange(sectionIndex, e.target.value)
              }
            />
            <button
              type="button"
              className="btn remove-section-btn"
              onClick={() => removeSection(sectionIndex)}
            >
              Remove Section
            </button>
          </div>

          {/* Options for the Section */}
          {section.options.map((option, optionIndex) => (
            <div key={optionIndex} className="form-group">
              <label htmlFor={`option_${sectionIndex}_${optionIndex}`}>
                Option {optionIndex + 1}
              </label>
              <input
                type="text"
                id={`option_${sectionIndex}_${optionIndex}`}
                value={option}
                onChange={(e) =>
                  handleOptionChange(sectionIndex, optionIndex, e.target.value)
                }
              />
              <button
                type="button"
                className="btn remove-option-btn"
                onClick={() => removeOption(sectionIndex, optionIndex)}
              >
                Remove Option
              </button>
            </div>
          ))}
          <button
            type="button"
            className="btn add-option-btn"
            onClick={() => addOption(sectionIndex)}
          >
            Add Option
          </button>
        </div>
      ))}

      <button
        type="button"
        className="btn add-section-btn"
        onClick={addSection}
      >
        Add Section
      </button>

      <div className="form-actions">
        <button type="button" className="btn btn-primary" onClick={handleSave}>
          Save
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateGeneralReportTemplate;
