// src/context/UserContext.js
import React, { createContext, useState } from "react";

// Create a context for User data
export const UserContext = createContext();

// Create a provider component that wraps the children
export const UserContextProvider = ({ children }) => {
  // Shared state for user data
  const [userRole, setUserRole] = useState(null);
  const [userID, setUserID] = useState(null);
  const [name, setName] = useState(null);

  return (
    <UserContext.Provider
      value={{ userRole, setUserRole, userID, setUserID, name, setName }}
    >
      {children}
    </UserContext.Provider>
  );
};
